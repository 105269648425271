<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="per_page"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="per_pageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            @click="addSchoolModal = true"
          >
            Add School
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <!-- Add School modal -->
    <b-modal
      v-model="addSchoolModal"
      hide-footer
      centered
      title="Add School"
    >
      <b-form-group
        label="Enter the School's Name"
        label-for="enterName"
      >
        <b-form-input
          id="enterName"
          v-model="addSchoolName"
          placeholder="Enter the School's Name"
        />
      </b-form-group>
      <b-form-group
        label="Enter the School's Address"
        label-for="enterAddress"
      >
        <b-form-input
          id="enterAddress"
          v-model="addSchoolAddress"
          placeholder="Enter the School's Address"
        />
      </b-form-group>

      <b-button
        variant="outline-primary"
        @click="submitSchool()"
      >
        Submit
      </b-button>
    </b-modal>

    <!-- Edit School modal -->
    <b-modal
      v-model="editSchoolModal"
      hide-footer
      centered
      title="Add School"
    >
      <b-form-group
        label="Enter the School's Name"
        label-for="enterName"
      >
        <b-form-input
          id="enterName"
          v-model="editSchoolName"
          placeholder="Enter the School's Name"
        />
      </b-form-group>
      <b-form-group
        label="Enter the School's Address"
        label-for="enterAddress"
      >
        <b-form-input
          id="enterAddress"
          v-model="editSchoolAddress"
          placeholder="Enter the School's Address"
        />
      </b-form-group>

      <b-button
        variant="outline-primary"
        @click="updateSchool"
      >
        Submit
      </b-button>
    </b-modal>

    <b-table
      ref="refSchoolListTable"
      :items="fetchSchools"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: No. of Teachers -->
      <template #cell(teachers)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.teachers.length }}</span>
        </div>
      </template>

      <!-- Column: No. of Students -->
      <template #cell(students)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.students.length }}</span>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="showEditSchoolModal(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteSchool(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="current_page"
            :total-rows="totalSchools"
            :per-page="per_page"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BFormGroup, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useSchoolList from './useSchoolList'

import schoolStoreModule from './schoolStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BModal,

    vSelect,
  },
  setup() {
    const SCHOOL_APP_STORE_MODULE_NAME = 'app-schools'

    const addSchoolModal = ref(false)

    const selectedTeacher = ref('')

    const options = []

    // Register module
    if (!store.hasModule(SCHOOL_APP_STORE_MODULE_NAME)) store.registerModule(SCHOOL_APP_STORE_MODULE_NAME, schoolStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCHOOL_APP_STORE_MODULE_NAME)) store.unregisterModule(SCHOOL_APP_STORE_MODULE_NAME)
    })

    const teachers = []

    const {
      fetchSchools,
      storeSchool,
      updateSchool,
      deleteSchool,
      tableColumns,
      per_page,
      current_page,
      totalSchools,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSchoolListTable,

      addSchoolAddress,
      addSchoolName,

      refetchData,

      editSchoolModal,
      editSchoolId,
      editSchoolName,
      editSchoolAddress,
    } = useSchoolList()

    const fetchTeachers = (() => {
      store.dispatch('app-schools/fetchAllTeachers')
        .then(response => {
          response.data.forEach(teacher => {
            teachers.push({ value: teacher.id, text: `${teacher.first_name} ${teacher.last_name}` })
          })
        })
        .catch(error => {
          console.log(error)
        })
    })

    fetchTeachers()

    const submitSchool = () => {
      storeSchool()
      addSchoolModal.value = false
      addSchoolName.value = ''
      addSchoolAddress.value = ''
    }

    const showEditSchoolModal = school => {
      editSchoolId.value = school.id
      editSchoolName.value = school.name
      editSchoolAddress.value = school.address
      editSchoolModal.value = true
    }

    return {
      fetchSchools,
      updateSchool,
      deleteSchool,
      tableColumns,
      per_page,
      current_page,
      totalSchools,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSchoolListTable,

      addSchoolModal,

      addSchoolName,
      addSchoolAddress,
      storeSchool,

      options,
      teachers,
      selectedTeacher,

      submitSchool,

      refetchData,

      showEditSchoolModal,

      editSchoolModal,
      editSchoolId,
      editSchoolName,
      editSchoolAddress,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
