import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("admin/categories", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAllCategories(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get("admin/categories/all")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addCategory(ctx, category) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/categories/add', category)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCategory(ctx, category) {
      console.log(category.name)
      return new Promise((resolve,reject) => {
        axios
          .post(`admin/categories/${category.id}/update`, category)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCategory(ctx, category) {
      console.log(category.id)
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/categories/${category.id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
};
