<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="per_page"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="per_pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-button
              variant="outline-primary"
              @click="addCategory()"
            >
              Add Category
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Add Category modal -->
      <b-modal
        v-model="addCategoryModal"
        hide-footer
        centered
        title="Add Category"
      >
        <b-form-group
          label="Enter Name"
          label-for="enterName"
        >
          <b-form-input
            id="enterName"
            v-model="addCategoryName"
            placeholder="Enter Category Name"
          />
          <br>
          <b-button
            variant="outline-primary"
            @click="submitCategory()"
          >
            Submit
          </b-button>
        </b-form-group>
      </b-modal>

      <!-- Update Category modal -->
      <b-modal
        v-model="updateCategoryModal"
        hide-footer
        centered
        title="Update Category"
      >
        <b-form-group
          label="Enter Name"
          label-for="enterName"
        >
          <b-form-input
            id="enterName"
            v-model="updateCategoryName"
            placeholder="Enter Category Name"
          />
          <br>
          <b-button
            variant="outline-primary"
            @click="submitUpdatedCategory()"
          >
            Update
          </b-button>
        </b-form-group>
      </b-modal>

      <b-table
        ref="refCategoryListTable"
        class="position-relative"
        :items="fetchCategories"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              variant="outline-warning"
              @click="updatedCategory(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-button>
            <b-button
              class="ml-1"
              variant="outline-danger"
              @click="deleteSelectedCategory(data.item.id)"
            >
              <feather-icon icon="DeleteIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalCategories"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BModal,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import useCategoryList from './useCategoryList'
import categoryStoreModule from './categoryStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BModal,
    BFormGroup,
    vSelect,
  },
  setup() {
    const CATEGORY_APP_STORE_MODULE_NAME = 'app-categories'

    const addCategoryModal = ref(false)
    const updateCategoryModal = ref(false)
    const addCategoryName = ref('')
    const updateCategoryName = ref('')

    // Register module
    if (!store.hasModule(CATEGORY_APP_STORE_MODULE_NAME)) { store.registerModule(CATEGORY_APP_STORE_MODULE_NAME, categoryStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CATEGORY_APP_STORE_MODULE_NAME)) store.unregisterModule(CATEGORY_APP_STORE_MODULE_NAME)
    })

    const {
      fetchCategories,
      storeCategory,
      updateCategory,
      deleteCategory,
      tableColumns,
      per_page,
      current_page,
      totalCategories,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCategoryListTable,
      refetchData,
      categoryName,
      categoryId,
    } = useCategoryList()

    const addCategory = () => {
      addCategoryModal.value = true
    }

    const updatedCategory = selected => {
      updateCategoryName.value = selected.name
      categoryId.value = selected.id
      updateCategoryModal.value = true
    }

    const deleteSelectedCategory = id => {
      deleteCategory((categoryId.value = id))
    }

    const submitCategory = () => {
      storeCategory((categoryName.value = addCategoryName.value))
      addCategoryName.value = ''
      addCategoryModal.value = false
    }

    const submitUpdatedCategory = () => {
      updateCategory(
        categoryId.value,
        (categoryName.value = updateCategoryName.value),
      )
      updateCategoryModal.value = false
    }

    const isAddNewUserSidebarActive = ref(false)

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchCategories,
      storeCategory,
      updateCategory,
      deleteCategory,
      deleteSelectedCategory,
      tableColumns,
      per_page,
      current_page,
      totalCategories,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCategoryListTable,
      refetchData,
      addCategory,
      addCategoryModal,
      addCategoryName,
      submitCategory,
      submitUpdatedCategory,
      updateCategoryModal,
      updateCategoryName,
      updatedCategory,
      categoryName,
      categoryId,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
