import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSchools(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("admin/schools", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSchool(ctx, school) {
      return new Promise((resolve, reject) => {
        axios
          .get(`admin/schools/${school}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllTeachers(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/teachers/all')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchAllSchools(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/schools/all')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSchool(ctx, school) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/schools', school)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateSchool(ctx, school) {
      return new Promise((resolve,reject) => {
        axios
          .put(`admin/schools/${school.id}`, school)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSchool(ctx, school) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/schools/${school.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }
  },
};
