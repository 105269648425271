<template>
  <b-card
    v-if="data"
    no-body
  >
    <b-card-header>
      <b-card-title class="ml-25">
        Activity Logs
      </b-card-title>
      <b-button :to="{ name: 'apps-logs-list' }">
        Show All
      </b-button>
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <app-timeline>
        <app-timeline-item v-for="logs in data" :key="logs.id"
          :title="logs.description"
          :time="moment(logs.created_at).fromNow()"
          :variant="getVariant()"
        />
      </app-timeline>
      <!--/ timeline -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BImg, BMedia, BMediaBody, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BButton
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Array,
      default: () => {},
    },
  },
  methods: {
    getVariant() {
      let variants = [
        'primary', 'info', 'warning', 'danger'
      ];

      let int = Math.floor(Math.random() * 3);

      return variants[int]
    }
  },
}
</script>
