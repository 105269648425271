<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col
        lg="12"
        sm="12"
      >
        <b-card title="Student Average Performance">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="mb-1 mb-sm-0">
              <span class="text-muted">Showing the students performance for 9 Months</span>
            </div>
          </div>

          <!-- echart -->
          <app-echart-line :option-data="performance" />

        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        lg="12"
        sm="12"
      >
        <b-card title="Student Registration Rate">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="mb-1 mb-sm-0">
              <span class="text-muted">Showing the student registration rate for the past 9 Months</span>
            </div>
          </div>

          <!-- echart -->
          <app-echart-line :option-data="students" />

        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-row>
          <b-col cols="6">
            <b-card 
              title="Number of Students"
              class="text-center"
            >
              <b-card-body>
                <h2>{{ students_count }}</h2>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card 
              title="Number of Teachers"
              class="text-center"
            >
              <b-card-body>
                <h2>{{ teachers_count }}</h2>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col cols="6">
            <b-card 
              title="Number of Chapters"
              class="text-center"
            >
              <b-card-body>
                <h2>{{ chapters_count }}</h2>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-card 
              title="Number of Lessons"
              class="text-center"
            >
              <b-card-body>
                <h2>{{ lessons_count }}</h2>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <analytics-timeline :data="logs" />
      </b-col>
      <b-col lg="6">
        <categories />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <schools />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCardBody, BCard, BCardTitle, BCardHeader } from 'bootstrap-vue'

import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import { kFormatter } from '@core/utils/filter'
import AnalyticsTimeline from './AnalyticsTimeline.vue'
import Categories from './category/Categories.vue'
import Schools from './school/Schools.vue'

export default {
  components: {
    BRow,
    BCardBody,
    BCol,
    BCard,
    BCardTitle, 
    BCardHeader,
    AnalyticsTimeline,
    Categories,
    Schools,
    AppEchartLine,
  },
  data() {
    return {
      students_count: 0,
      teachers_count: 0,
      chapters_count: 0,
      lessons_count: 0,
      logs: [],
      students: {
        xAxisData: [],
        series: [],
      },
      performance: {
        xAxisData: [],
        series: []
      }
    }
  },
  async beforeMount() {
    await this.getDashboardData()
  },
  methods: {
    kFormatter,
    getDashboardData() {
      this.getLogs()
      this.getData()
    },
    getLogs() {
      this.$http.get('/admin/logs/recent')
        .then(response => {this.logs = response.data})
    },
    getData() {
      this.$http.get('/admin/dashboard/data')
        .then(response => {
          this.students_count = response.data.students.length
          this.teachers_count = response.data.teachers.length
          this.chapters_count = response.data.chapters_count
          this.lessons_count = response.data.lessons_count
          response.data.student_registration_rate.months.reverse().forEach(item => {
            this.students.xAxisData.push(item)
          })
          response.data.student_registration_rate.total_users.reverse().forEach(item => {
            this.students.series.push(item)
          })
          response.data.student_performance_rate.months.reverse().forEach(item => {
            this.performance.xAxisData.push(item)
          })
          response.data.student_performance_rate.total_performance.reverse().forEach(item => {
            this.performance.series.push(item/100)
          })
        })
    }
  },
}
</script>
