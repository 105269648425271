import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCategoryList() {
  // Use toast
  const toast = useToast()

  const refCategoryListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name' },
    { key: 'actions' },
  ]
  const per_page = ref(5)
  const totalCategories = ref(0)
  const current_page = ref(1)
  const per_pageOptions = [5, 10, 15, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const categoryName = ref('')
  const categoryId = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCategoryListTable.value ? refCategoryListTable.value.localItems.length : 0
    return {
      from: per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalCategories.value,
    }
  })

  const refetchData = () => {
    refCategoryListTable.value.refresh()
  }

  watch([current_page, per_page, searchQuery], () => {
    refetchData()
  })

  const fetchCategories = (ctx, callback) => {
    store
      .dispatch('app-categories/fetchCategories', {
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then(response => {
        const { data: categories, total } = response.data.data

        callback(categories)
        totalCategories.value = total
      })
      .catch((error) => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching categories list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchAllCategories = (ctx, callback) => {
    store
      .dispatch('app-categories/fetchAllCategories')
      .then(response => {
        const { data: categories, total } = response.data

        callback(categories)
        totalCategories.value = total
      })
      .catch((error) => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching categories list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const storeCategory = (ctx, callback) => {
    store
      .dispatch('app-categories/addCategory', {name: categoryName.value})
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Category added.',
            icon: 'AlertTriangleIcon',
            variant: 'success'
          }
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred while adding the category.',
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      })
  }

  const updateCategory = () => {
    store
      .dispatch('app-categories/updateCategory', {id: categoryId.value, name: categoryName.value})
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Category updated.',
            icon: 'AlertTriangleIcon',
            variant: 'success'
          }
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred while updating the category.',
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      })
  }

  const deleteCategory = (ctx, callback) => {
    store.dispatch('app-categories/deleteCategory', {id: categoryId.value})
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Category deleted.',
            icon: 'AlertTriangleIcon',
            variant: 'success'
          }
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting category.',
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
      })
  }

  return {
    fetchCategories,
    fetchAllCategories,
    storeCategory,
    updateCategory,
    deleteCategory,
    tableColumns,
    per_page,
    current_page,
    totalCategories,
    dataMeta,
    per_pageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCategoryListTable,
    refetchData,
    categoryName,
    categoryId
  }
}
