import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useSchoolList() {
  // Use toast
  const toast = useToast();

  const refSchoolListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name", sortable: true },
    { key: "address", sortable: true },
    { key: "teachers", sortable: true },
    { key: "students", sortable: true},
    { key: "actions" },
  ];
  const per_page = ref(5);
  const totalSchools = ref(0);
  const current_page = ref(1);
  const per_pageOptions = [5, 10, 15, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const addSchoolName = ref("");
  const addSchoolAddress = ref("");
  const schoolId = ref("");

  const schoolData = ref(null)

  const editSchoolModal = ref(false)
  const editSchoolId = ref('')
  const editSchoolName = ref('')
  const editSchoolAddress = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refSchoolListTable.value
      ? refSchoolListTable.value.localItems.length
      : 0;
    return {
      from:
        per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalSchools.value,
    };
  });

  const refetchData = () => {
    refSchoolListTable.value.refresh();
  };

  watch([current_page, per_page, searchQuery], () => {
    refetchData();
  });

  const fetchSchools = (ctx, callback) => {
    store
      .dispatch("app-schools/fetchSchools", {
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then((response) => {
        const { data: schools, total } = response.data.data;
        callback(schools);
        totalSchools.value = total;
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching schools list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const storeSchool = (ctx, callback) => {
    store
      .dispatch("app-schools/addSchool", {
        name: addSchoolName.value,
        address: addSchoolAddress.value,
      })
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: "School added.",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while adding the school.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const updateSchool = () => {
    store
      .dispatch("app-schools/updateSchool", {
        id: editSchoolId.value,
        name: editSchoolName.value,
        address: editSchoolAddress.value,
      })
      .then(() => {
        refetchData();
        editSchoolId.value = ''
        editSchoolName.value = ''
        editSchoolAddress.value = ''
        editSchoolModal.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: "School updated.",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        });
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "An error occurred while updating the school.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const deleteSchool = (school) => {
    store
      .dispatch("app-schools/deleteSchool", { id: school })
      .then((response) => {
        refetchData();
        toast({
          component: ToastificationContent,
          props: {
            title: "School deleted.",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        });
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error deleting school.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchSchools,
    storeSchool,
    updateSchool,
    deleteSchool,
    tableColumns,
    per_page,
    current_page,
    totalSchools,
    dataMeta,
    per_pageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSchoolListTable,
    refetchData,
    schoolId,
    addSchoolAddress,
    addSchoolName,

    schoolData,

    editSchoolModal,
    editSchoolId,
    editSchoolName,
    editSchoolAddress,
  };
}
